@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* > * {
  font-family: "Poppins", sans-serif;
  /* font-family: 'Open Sans',  'Roboto',"Fira Sans", "Helvetica Neue", "Apple Color Emoji", "sans-serif"; */
  /* font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
}

@media print {
  #divToPrint {
    transform: scale(0.75);
    transform-origin: top left;
  }
}
