/* event background and search  */
.dashboard-background {
  background-image: url(../images/bg/07.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.bg-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(var(--bs-dark), var(--bs-bg-opacity)) !important;
}

.bg-mode {
  background: #fff;
  border-radius: var(--bs-border-radius) !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

/* p {
  margin: 0 auto;
  width: fit-content;
} */

.app-btn-xs {
  padding: 0.4rem 0.6rem !important;
  font-size: 0.6rem !important;
  line-height: 1.2 !important;
  border-radius: 0.2rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.bg-light {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  background-color: (var(--bs-dark), var(--bs-bg-opacity)) !important;
}

.h-400px {
  max-height: 400px !important;
}

.h-450px {
  max-height: 450px !important;
}

.h-500px {
  max-height: 500px !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.app-card-title {
  display: inline-block;
  background-color: var(--app-btn-primary) !important;
  color: white !important;
  border-radius: var(--bs-border-radius-sm) !important;
  height: 3rem;
}

.caption-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: #fff;
  padding: 5px;
  text-align: center;
}

.caption-text {
  margin: 0;
  font-size: 14px;
}
