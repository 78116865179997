/* Login */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(80vh - 35px); /* Assuming the header is 60px tall */
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.form-wrapper {
  width: 400px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.form-wrapper h1 {
  margin-bottom: 2rem;
  text-align: center;
}

/* .form-wrapper .form-group label {
  font-size: large;
} */

.form-wrapper .btn-primary {
  width: 100%;
}

.form-wrapper .link-btn {
  font-size: 1rem;
  color: darkred;
}

.app-btn-lnk-sm {
  font-size: 0.8rem !important;
}

.form-wrapper .form-label {
  font-size: 16px;
}

.form-wrapper .link-btn {
  font-size: small;
  color: darkred;
}

.form-wrapper .form-input {
  font-size: small;
  border: 1px solid var(--app-border-secondary);
}

/* Register Form */
.form-wrapper-register {
  width: 540px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.form-wrapper-register .link-btn {
  font-size: small;
  color: darkred;
}

.form-wrapper-register .form-check-label {
  font-size: small;
}

.form-wrapper-register .form-input {
  font-size: small;
  border: 1px solid var(--app-border-secondary);
}

/* Register Form */
.form-wrapper-event {
  width: 480px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.login-container {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: 360px;
  height: min-content;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
}

.login-container h1 {
  font-size: 24px;
}

.login-container form {
  font-size: 18px;
}

.login-container .form-label {
  text-align: left;
}

.login-container .form-group {
  margin-bottom: 12px;
}

.login-container form input[type="submit"] {
  font-size: 20px;
  margin-top: 15px;
}

.login-container button {
  background-color: #08630b;
  color: #fff;
  padding: 10px 10px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.login-container button:hover {
  background-color: #118817;
  color: #fff;
}
