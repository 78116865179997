*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

:root {
  /* colors */
  --primary-100: #e6f0ff;
  --primary-200: #b4d3fe;
  --primary-300: #82b6fd;
  --primary-400: #5098fc;
  --primary-500: #03449d;
  --primary-600: #034caf;
  --primary-700: #02367d;
  --primary-800: #01214b;
  --primary-900: #000b19;

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;
  --bs-dark: #202020;
  --bs-bg-opacity: 1;

  /* Theme 1 */
  /* --app-bg-primary: #FF5722;
  --app-bg-secondary: #03A9F4;
  --app-bg-primary: #FF5722;
  --app-bg-secondary: #03A9F4;
  --app-bg-card-header: #009688;
  --app-bg-card: #f1f5f9;
  --app-icon-primary: #FF5722;
  --app-btn-primary: #FF5722;
  --app-btn-primary-hover: #f86e2e;
  --app-btn-secondary: #03A9F4;
  --app-btn-secondary-hover: #14A9f4;
  --app-text-primary: #222222;
  --app-text-secondary: #222222;
  --app-text-accent1: #CDDC39;
  --app-text-accent2: #FFEB3B;
  --app-border-primary: #03A9F4;
  --app-border-secondary: #FF5722; */

  /* Theme 2 */
  /* --app-bg-primary: #003366;
  --app-bg-secondary: #36454F;
  --app-bg-primary: #003366;
  --app-bg-secondary: #36454F;
  --app-bg-card-header: #36454F;
  --app-bg-card: #D3D3D3;
  --app-icon-primary: #FF5722;
  --app-btn-primary: #003366;
  --app-btn-primary-hover: #063564;
  --app-btn-secondary: #36454F;
  --app-btn-secondary-hover: #3b4850;
  --app-text-primary: #333333;
  --app-text-secondary: #222222;
  --app-text-accent1: #003366;
  --app-text-accent2: #FFEB3B;
  --app-border-primary: #003366;
  --app-border-secondary: #36454F; */

  /* Theme 3 */
  --app-bg-primary: #f3722c;
  --app-bg-secondary: #424242;
  --app-bg-skyblue: #87ceeb;
  --app-bg-skyblue-light: #bbd8e8;
  --app-bg-navyblue: #001f3f;
  --app-bg-teal: #48c9b0;
  --app-bg-teal-light: #90e4c1;
  --app-bg-grey: #f7f7f7;
  /* --app-bg-card-header: #424242; #a1d6eb*/
  --app-bg-card-header: #008080;
  --app-bg-card: #f7f7f7;
  --app-icon-primary: #008080;
  --app-icon-primary-hover: #00a3a3;
  /* --app-btn-primary: #008080; */
  /* --app-btn-primary-hover: #00A3A3; */
  --app-btn-primary: #f3722c;
  --app-btn-primary-hover: #fa824e;
  --app-btn-secondary: #d3d3d3;
  --app-btn-secondary-hover: #d3d3d3;
  --app-btn-accent: #ffa07a;
  --app-btn-accent-hover: #f39360;
  --app-text-primary: #333333;
  --app-text-secondary: #222222;
  --app-text-accent1: #008080;
  --app-text-accent2: #ffa07a;
  --app-text-accent3: #665945;
  --app-text-accent4: #2e8080;
  --app-text-accent5: #f3722c;
  --app-border-primary: #f3722c;
  --app-border-secondary: #007373;
  --app-border-accent1: #ffa07a;

  /* fonts  */
  --app-fs-7: 0.7rem;
  --app-fs-8: 0.8rem;
  --app-fs-9: 0.9rem;
  --app-fs-10: 1rem;
  --app-fs-11: 1.1rem;
  --app-fs-12: 1.2rem;
  --app-fs-13: 1.3rem;
  --app-fs-14: 1.4rem;
  --app-fs-15: 1.5rem;

  /* rest of the vars */
  --backgroundColor: var(--white);
  /* --backgroundColor: var(--grey-100); */
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --bs-border-radius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;
  --view-width: 90vw;
  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-sm: 0.25rem;
}

body {
  background: var(--backgroundColor);
  font-family: "Poppins", sans-serif, "Sharp Sans", "Arial", "Helvetica Neue",
    system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif Segoe UI,
    Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25;
  color: var(--textColor);
  /* Ensure the body covers the entire viewport height. */
  padding-top: 70px;
  /* min-height: 100vh; */
  /* Create a stacking context for child elements. */
  /* position: relative; */
}

/* Media query for small screens */
@media (max-width: 576px) {
  .img-responsive {
    width: 50%; /* On small screens, the image will take up 50% of its container */
  }
  .h4-responsive {
    font-size: 0.8rem !important; /* Smaller font size for small screens */
  }
  .tab-responsive {
    font-size: 0.6rem !important; /* Smaller font size for small screens */
  }
  .btn-tab-responsive {
    padding: 2px 3px !important; /* Smaller button size for small screens */
    font-size: 0.6rem !important; /* Smaller font size for small screens */
  }
  .nav-tabs {
    padding: 1px !important;
  }
  .font-responsive {
    font-size: 0.6rem !important; /* Default font size */
  }
  .card-img-overlay {
    max-height: 200px !important;
    overflow: auto !important;
  }
}
/* Media query for medium screens */
@media (min-width: 577px) and (max-width: 768px) {
  .img-responsive {
    width: 75%; /* On medium screens, the image will take up 75% of its container */
  }
  .h4-responsive {
    font-size: 1rem !important; /* Smaller font size for small screens */
  }
  .tab-responsive {
    font-size: 0.6rem; /* Smaller font size for small screens */
  }
  .btn-tab-responsive {
    padding: 2px 3px !important; /* Smaller button size for small screens */
    font-size: 0.5rem !important; /* Smaller font size for small screens */
  }
  .img-skewed {
    transform: skewX(0deg); /* Don't skew the image on small screens */
  }
  .font-responsive {
    font-size: 0.7rem !important; /* Default font size */
  }
  .card-img-overlay {
    max-height: 200px !important;
    overflow: auto !important;
  }
}

@media (max-width: 768px) {
  .img-responsive {
    width: 100%; /* On small screens, the image will take up 50% of its container */
  }
  .img-skewed-item {
    transform: none !important; /* Reset the transform property on small screens */
  }
  .h4-responsive {
    font-size: 1rem !important; /* Smaller font size for small screens */
  }
  .font-responsive {
    font-size: 0.8rem !important; /* Default font size */
  }
  .container {
    width: 100%;
    padding: 1rem;
  }
  .custom-width {
    width: 95%;
  }
  h1 {
    font-size: 1.5rem;
  }
  p {
    line-height: 1.5;
  }
  img {
    max-width: 100%;
  }

  /* Stack elements on top of each other */
  .stacked {
    display: flex;
    flex-direction: column;
  }
  /* Button adjustments */
  .btn-responsive {
    padding: 4px 6px !important; /* Smaller button size for small screens */
    font-size: 0.7rem !important; /* Smaller font size for small screens */
    background-color: var(--app-btn-primary) !important;
    color: #fff !important;
  }
  .btn-responsive:hover {
    background-color: var(--app-btn-primary-hover) !important;
    color: #fff !important;
  }
  .tab-responsive {
    font-size: 0.7rem; /* Smaller font size for small screens */
  }
  .btn-tab-responsive {
    padding: 3px 5px !important; /* Smaller button size for small screens */
    font-size: 0.7rem !important; /* Smaller font size for small screens */
  }
  button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
  .dash-sidenav {
    width: 100%; /* Adjust as needed */
  }
  .smaller-tabs {
    font-size: 0.6rem; /* Adjust as needed */
  }
  .smaller-buttons {
    padding: 0.1rem 0.4rem !important;
    font-size: 0.6rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    max-width: 768px;
    margin: 0 auto;
  }
  .custom-width {
    width: 95%;
  }
  .font-responsive {
    font-size: 0.8rem !important; /* Default font size */
  }
  h1 {
    font-size: 2rem;
  }
  p {
    font-size: 1rem;
  }
  /* Adjust layout for more space */
  .stacked {
    flex-direction: row;
  }
  /* Increase button size */
  button {
    padding: 1rem 2rem;
    font-size: 1.2rem;
  }
  .dash-sidenav {
    width: 100%; /* Adjust as needed */
  }
  .smaller-tabs {
    font-size: 0.7rem; /* Adjust as needed */
  }
  .smaller-buttons {
    padding: 0.1rem 0.4rem !important;
    font-size: 0.7rem !important;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  .custom-width {
    width: 85%;
  }
  .font-responsive {
    font-size: 0.9rem !important; /* Default font size */
  }
  .programDiv {
    min-height: 100px;
  }
  h1 {
    font-size: 1.2rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  p {
    font-size: 1rem;
  }
  /* Further refine layout for larger screens */
  .stacked {
    justify-content: space-between;
  }
}
@media print {
  @page {
    size: A4 portrait;
  }
  .bg-overlay,
  .card-img-overlay {
    display: none;
  }
  .card {
    box-shadow: none;
  }
  .app-bg-skyblue {
    background-color: var(--app-bg-skyblue);
  }
  .row {
    page-break-inside: avoid;
  }
  .action-buttons {
    display: none;
  }
  /* Add other styles as needed */
}

img {
  max-width: 100%;
  height: auto;
}

img,
svg {
  vertical-align: middle;
}

.card-img-overlay {
  max-height: none;
  overflow: visible;
}

.logo {
  /* height: 6rem; */
  width: 5rem;
  position: relative;
  display: inline-block;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

footer {
  /* background-color: var(--app-bg-pc1); */
  padding-top: 2em;
  padding-bottom: 2em;
  /* text-align: center; */
  margin-top: 2rem;
  width: 100%;
  font-size: 70%;
  background-color: #f5f5f5;
}

/* Navigation Tabs */
.nav-tabs .nav-item .nav-link {
  margin: 0 1px;
  color: var(--app-text-primary);
  background-color: var(--app-bg-teal-light);
}

.nav-tabs .nav-item .nav-link.active {
  background-color: var(--app-bg-secondary);
  color: #fff;
}

.nav-pills .nav-link {
  margin: 0 1px;
  background-color: white;
}

.nav-pills .nav-link.active {
  background-color: var(--app-bg-teal) !important;
  color: #fff;
}

/* Image styles */
.img-skewed-start {
  perspective-origin: 0;
}

.img-skewed {
  perspective: 1300px;
  transform-style: preserve-3d;
}

.img-skewed-item {
  width: 30rem;
  transition: transform 0.5s;
  transform: perspective(1000px) rotateY(-20deg);
}

/* Font sizes */
.fw-bold-600 {
  font-weight: 600;
}

.fw-bold-500 {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.1;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.1rem;
}

h6 {
  font-size: 0.9rem;
}

.font-responsive {
  font-size: 0.9rem !important; /* Default font size */
}

.h4-responsive {
  font-size: 1.5rem; /* Default font size */
}

.h5-responsive {
  font-size: 1.1rem; /* Default font size */
}

small,
.small {
  font-weight: 400;
}

small,
.small {
  font-size: 0.875em;
}

.app-fs-7 {
  font-size: var(--app-fs-7);
}

.app-fs-8 {
  font-size: var(--app-fs-8);
}

.app-fs-9 {
  font-size: var(--app-fs-9);
}

.app-fs-10 {
  font-size: var(--app-fs-10);
}
.app-fs-11 {
  font-size: var(--app-fs-11);
}
.app-fs-12 {
  font-size: var(--app-fs-12);
}
.app-fs-13 {
  font-size: var(--app-fs-13);
}
.app-fs-14 {
  font-size: var(--app-fs-14);
}
.app-fs-15 {
  font-size: var(--app-fs-15);
}

.app-link-fs-7 {
  font-size: var(--app-fs-7) !important;
  color: var(--app-text-secondary) !important;
}

.app-link-fs-8 {
  font-size: var(--app-fs-8) !important;
  color: var(--app-text-secondary) !important;
}

.app-link-fs-9 {
  font-size: var(--app-fs-9) !important;
  color: var(--app-text-primary) !important;
}

.app-link-fs-10 {
  font-size: var(--app-fs-10) !important;
  color: var(--app-text-primary) !important;
}

small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}

/* Sidenavs */
.chat-sidenav {
  /* Full height */
  height: 100vh;
  /* height: min-content; */
  /* Enable scroll if needed */
  overflow-y: auto;
}

.sidenav {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

/* Text Colors */
.text-gray-700 {
  /* color: #4a5568; */
  color: var(--grey-700);
}

.app-text-primary {
  color: var(--app-text-primary);
}

.app-text-secondary {
  color: var(--app-text-secondary);
}

.app-text-accent1 {
  color: var(--app-text-accent1);
}

.app-text-accent2 {
  color: var(--app-text-accent2);
}

.app-text-accent3 {
  color: var(--app-text-accent3);
}

.app-text-accent4 {
  color: var(--app-text-accent4);
}

.app-text-accent5 {
  color: var(--app-text-accent5);
}

.app-text-accent3-xs {
  color: var(--app-text-accent3);
  font-size: 0.7rem !important;
}

.app-text-success {
  font-size: 0.8rem;
  color: green;
}

.app-text-error {
  font-size: 0.8rem;
  color: red;
}

.help-text-xs {
  font-size: 0.7rem !important;
  color: var(--app-text-accent3) !important;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 10px;
}

.status-message {
  color: green;
  font-size: 0.8em;
  margin-top: 10px;
}

.fc-fs-7 {
  font-size: 0.7rem !important;
}

.fc-fs-8 {
  font-size: 0.8rem !important;
}

.fl-fs-8 {
  font-size: 0.8rem !important;
}

.fl-fs-9 {
  font-size: 0.9rem !important;
}

/* Icon Styles */

.feature-icon-primary {
  color: var(--app-bg-primary);
}

.feature-icon-secondary {
  color: var(--app-bg-secondary);
}

.icon-square {
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
}

.app-icon-primary-xs {
  cursor: pointer;
  padding: 0.1rem 0.4rem;
  font-size: 0.7rem;
  /* line-height: 1.4; */
  border-radius: 0.2rem;
  background-color: var(--app-icon-primary);
  color: white;
}

.app-icon-primary-xs:hover {
  background-color: var(--app-icon-primary-hover);
  color: var(--app-text-primary);
}

.app-icon-sidenav {
  cursor: pointer;
  color: var(--app-icon-primary);
}

.app-icon-delete {
  cursor: pointer;
  color: red;
}

.app-icon-style {
  cursor: pointer;
  color: #fff;
}

.del-icon {
  background: transparent;
  border: transparent;
  font-size: 1rem;
  cursor: pointer;
  transition: var(--transition);
  color: rgb(194, 3, 3);
}

.del-icon:hover {
  color: var(--red-dark);
  transform: translateY(-1px);
}

/* Background Colors */
.app-bg-custom {
  background-color: var(--app-bg-teal);
}

.app-bg-primary {
  background-color: var(--app-bg-primary);
}

.app-bg-secondary {
  background-color: var(--app-bg-secondary);
}

.app-bg-teal {
  background-color: var(--app-bg-teal);
}

.app-bg-skyblue {
  background-color: var(--app-bg-skyblue);
}

.app-bg-skyblue-light {
  background-color: var(--app-bg-skyblue-light);
}

.app-bg-grey {
  background-color: var(--app-bg-grey) !important;
}

.app-bg-navyblue {
  background-color: var(--app-bg-navyblue);
}

/* Card Styles */
.card {
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
}

.card-header {
  background-color: var(--app-bg-card-header) !important;
  border-radius: 0.3rem;
  color: #fff !important;
  font-size: 1.1rem;
}

.app-card- .app-card-header {
  background-color: var(--app-bg-card-header) !important;
  min-height: 2rem;
  /* padding-top: 6px;
  padding-left: 10px; */
  z-index: 10;
  display: block;
  padding: 0.4rem;
  font-size: 1rem;
  /* line-height: 1.0; */
  border-radius: 0.3rem;
  color: #fff !important;
}

.app-card-subtitle {
  font-size: 1rem !important;
  font-weight: bold;
  color: var(--app-text-accent5) !important;
}

/* Border Styles */
.app-border-primary {
  border-color: var(--app-border-primary) !important;
}

.app-border-secondary {
  border-color: var(--app-border-secondary) !important;
}

/* Button Styles */
.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}

.btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}

.text-button {
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.btn-back {
  color: var(--app-btn-primary);
}

.bd-clipboard {
  display: block;
}

.bd-clipboard {
  float: right;
}

.app-btn-clipboard {
  /* position: absolute;
  top: .65rem;
  right: .65rem; */
  z-index: 10;
  display: block;
  padding: 0.25rem 0.5rem;
  font-size: 0.65em;
  color: var(--app-text-primary);
  background-color: #fff;
  border: 1px solid var(--app-border-primary);
  border-radius: 0.25rem;
}

.app-btn-primary {
  background-color: var(--app-btn-primary) !important;
  color: #fff !important;
}

.app-btn-primary:hover {
  background-color: var(--app-btn-primary-hover) !important;
  color: #fff !important;
}

.app-btn-primary-md {
  font-size: 0.8rem !important;
  background-color: var(--app-btn-primary) !important;
  color: #fff !important;
}

.app-btn-primary-md:hover {
  font-size: 0.8rem !important;
  background-color: var(--app-btn-primary-hover) !important;
  color: #fff !important;
}

/* .action-buttons {
  display: block;
  margin-top: 1rem;
} */

.action-buttons .app-btn-primary {
  background-color: var(--app-btn-primary) !important;
  color: #fff !important;
}

.action-buttons .app-btn-primary:hover {
  background-color: var(--app-btn-primary-hover) !important;
  color: #fff !important;
}

.action-buttons .app-btn-secondary {
  background-color: var(--app-btn-secondary) !important;
  color: black !important;
}

.action-buttons .app-btn-secondary:hover {
  background-color: var(--app-btn-secondary-hover) !important;
  color: black !important;
}

.action-buttons .app-btn-accent {
  background-color: var(--app-btn-accent) !important;
  color: black !important;
}

.action-buttons .app-btn-accent:hover {
  background-color: var(--app-btn-accent-hover) !important;
  color: black !important;
}

.action-buttons .app-btn-primary-md {
  z-index: 10;
  cursor: pointer;
  display: block;
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem;
  /* line-height: 1.5; */
  border-radius: 0.2rem;
  background-color: var(--app-btn-primary);
  color: #fff;
}

.action-buttons .app-btn-primary-md:hover {
  background-color: var(--app-btn-primary-hover);
  color: #fff;
}

.action-buttons .app-btn-primary-xs {
  cursor: pointer;
  padding: 0.1rem 0.4rem;
  font-size: 0.6rem;
  /* line-height: 1.4; */
  border-radius: 0.2rem;
  background-color: var(--app-btn-primary);
  color: #fff;
}

.action-buttons .app-btn-primary-xs:hover {
  background-color: var(--app-btn-primary-hover);
  color: #fff;
}

.action-buttons .app-btn-secondary-xs {
  cursor: pointer;
  padding: 0.1rem 0.4rem;
  font-size: 0.6rem;
  /* line-height: 1.4; */
  border-radius: 0.2rem;
  background-color: var(--app-btn-secondary);
  color: black;
}

.action-buttons .app-btn-secondary-xs:hover {
  background-color: var(--app-btn-secondary-hover);
  color: black;
}

.action-buttons .app-btn-accent {
  background-color: var(--app-btn-accent) !important;
  color: black !important;
}

.action-buttons .app-btn-accent:hover {
  background-color: var(--app-btn-accent-hover) !important;
  color: black !important;
}

.action-buttons .app-icon-primary-xs {
  cursor: pointer;
  padding: 0.1rem 0.4rem;
  font-size: 0.7rem;
  /* line-height: 1.4; */
  border-radius: 0.2rem;
  background-color: var(--app-icon-primary);
  color: white;
}

.action-buttons .app-icon-primary-xs:hover {
  background-color: var(--app-icon-primary-hover);
  color: var(--app-text-primary);
}

.action-buttons .hideBtn {
  display: none;
}

.action-buttons .app-btn-primary-md {
  z-index: 10;
  cursor: pointer;
  display: block;
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem;
  /* line-height: 1.5; */
  border-radius: 0.2rem;
  background-color: var(--app-btn-primary);
  color: #fff;
}

.action-buttons .app-btn-primary-md:hover {
  background-color: var(--app-btn-primary-hover);
  color: #fff;
}

.action-buttons .app-btn-primary-sm {
  z-index: 10;
  cursor: pointer;
  display: block;
  padding: 0.1rem 0.4rem;
  font-size: 0.7rem;
  /* line-height: 1.5; */
  border-radius: 0.2rem;
  background-color: var(--app-btn-primary);
  color: #fff;
}

.action-buttons .app-btn-primary-sm:hover {
  background-color: var(--app-btn-primary-hover);
  color: #fff;
}

.action-buttons .app-btn-secondary {
  background-color: var(--app-btn-secondary) !important;
  color: black !important;
}

.action-buttons .app-btn-secondary:hover {
  background-color: var(--app-btn-secondary-hover) !important;
  color: black !important;
}

.action-buttons .app-btn-secondary-sm {
  z-index: 10;
  cursor: pointer;
  display: block;
  padding: 0.1rem 0.4rem;
  font-size: 0.7rem;
  /* line-height: 1.5; */
  border-radius: 0.2rem;
  background-color: var(--app-btn-secondary);
  color: #fff;
}

.action-buttons .app-btn-secondary-sm:hover {
  background-color: var(--app-btn-secondary-hover);
  color: #fff;
}

.action-buttons .btn-responsive {
  z-index: 10;
  display: block;
  border: 1px solid;
  border-radius: 0.25rem;
}

.action-buttons .btn-responsive {
  padding: 0.5rem 0.8rem;
  font-size: 0.75em;
  background-color: var(--app-btn-primary) !important;
  color: #fff !important;
}

.action-buttons .btn-responsive:hover {
  background-color: var(--app-btn-primary-hover) !important;
  color: #fff !important;
}

.btn-responsive {
  z-index: 10;
  display: block;
  border: 1px solid;
  border-radius: 0.25rem;
}

.btn-responsive {
  padding: 0.5rem 0.8rem;
  font-size: 0.65em;
  background-color: var(--app-btn-primary) !important;
  color: #fff !important;
}

.btn-responsive:hover {
  background-color: var(--app-btn-primary-hover) !important;
  color: #fff !important;
}

.app-btn-white {
  color: white !important;
}

/* Accordion Styles  */
.accordion .accordion-button {
  background-color: var(--bs-light);
}

.accordion .accordion-button[aria-expanded="true"] {
  background: var(--app-bg-secondary) !important;
  color: var(--bs-white);
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: grid;
  place-items: center;
  transition: var(--transition);
  z-index: 1000;
}

.modal-container {
  position: relative;
  width: 60vw;
  max-width: 600px;
  height: auto;
  overflow-y: auto;
  background: var(--white);
  border-radius: var(--borderRadius);
}

.modal-container-msg {
  max-width: 80vw;
  height: 50vh;
  overflow-y: auto;
  background: var(--white);
  border-radius: var(--borderRadius);
}

.modal-img {
  height: 15rem;
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
  cursor: pointer;
}

.modal-content {
  padding: 1rem 1.5rem;
  max-width: 600px;
}

.modal-content p {
  color: var(--grey-600);
}

.modal-content a {
  display: block;
  color: var(--primary-500);
  margin-bottom: 1rem;
  text-decoration: underline;
  transition: var(--transition);
}

.modal-content a:hover {
  color: black;
}

/* Avatar Styles */
.avatar-group {
  padding: 0;
  display: flex;
}

.avatar-group > li:not(:last-child) {
  margin-right: -0.8rem;
}

.avatar-group > li {
  position: relative;
}

.avatar-group .avatar-img {
  border: 2px solid #fff;
}

.avatar-xs {
  height: 2.1875rem;
  width: 2.1875rem;
}

.avatar-md {
  height: 5rem;
  width: 5rem;
  position: relative;
  display: inline-block;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.avatar-md-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar {
  height: 2rem;
  width: 2rem;
  position: relative;
  display: inline-block;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.rounded-circle {
  border-radius: 50% !important;
}

.icon-img-md {
  width: 100px !important;
}

.img-responsive {
  z-index: 1;
}

/* Event */

/* Contact Us Page */
.contact-us {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: max-content; */
  height: 70vh;
  padding: 0.5rem;
}

.contact-us-wrapper {
  max-width: 375px;
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid var(--app-border-secondary);
  border-radius: 10px;
}

.contact-us-wrapper .form-input {
  font-size: small;
  border: 1px solid var(--app-border-secondary);
}

/* Miscellaneous */
.app-border-color {
  border-color: var(--app-border-primary);
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

/* list-style-type: "\1F44E";  thumbs-down
list-style-type: "\1F44D";  thumbs-up */
.list-styled {
  line-height: 0.7;
}

.row-text-sm {
  font-size: 0.8rem !important;
  justify-content: center;
  align-items: center;
}

.row-text-xm {
  font-size: 0.7rem !important;
  justify-content: center;
  align-items: center;
}

.show-img {
  display: inline;
}

.hide-img {
  display: none;
}

.file-input {
  display: none;
}

.offcanvas {
  transition: transform 0.3s ease-in-out;
}

.hide-placeholder:focus::placeholder {
  color: transparent;
}

.form-wrapper-gen {
  /* display: flex; */
  padding-left: 0.1rem;
}

.form-wrapper-gen .form-input {
  font-size: small;
  border: 1px solid var(--app-border-secondary);
}

.tab-responsive {
  font-size: 1rem; /* Default font size */
}

.hideDiv {
  display: none;
}

.showDiv {
  display: block;
}

.profile-img {
  max-width: 100px;
  border-color: grey;
}

#profile {
  display: none;
}

#profile-img {
  cursor: pointer;
}

.ql-editor {
  min-height: 200px;
}

.highlight {
  background-color: yellow;
  /* Change this to your preferred highlight color */
}

.strikethrough {
  text-decoration: line-through;
  color: rgb(194, 3, 3);
}

.mw-md-25 {
  max-width: 25rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.shape {
  pointer-events: none;
  position: absolute;
}

.shape-blur-4 {
  bottom: 0;
  left: 50%;
  top: 0;
  transform: translateX(-50%) scale(1.3);
  width: 100%;
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
}

.conversation {
  overflow-y: auto; /* Enable vertical scrollbar if necessary */
  max-height: 40vh; /* Adjust as needed */
  scrollbar-color: var(--grey-500) var(--grey-100);
  /* scrollbar-width: thin; */
}

hr {
  background-color: red;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  border-color: inherit;
}

.animation-line {
  display: block;
}

/* Custom Button with Arrow */
.custom-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--app-bg-primary);
  color: white;
  border: none;
  cursor: pointer; /* Change cursor to pointer on hover */
  position: relative; /* Required for pseudo-element positioning */
}

.custom-button span {
  display: inline-block;
  transition: transform 0.3s ease; /* Adjust timing as needed */
}

.custom-button:hover {
  background-color: var(--app-btn-primary-hover) !important;
}

.custom-button:hover span {
  background-color: var(--app-btn-primary-hover) !important;
  transform: translateX(-10px); /* Adjust distance as needed */
}

/* Pseudo-element for the arrow */
.custom-button:hover::after {
  content: "\2192"; /* Unicode arrow character (right arrow) */
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust as needed */
  transform: translateY(-50%);
  font-size: 16px;
  transition: transform 0.3s ease; /* Adjust timing as needed */
}

.event-card-image {
  overflow: hidden;
}

.custom-image {
  /* Smooth scaling transition */
  transition: transform 0.3s ease;
}

.custom-image:hover {
  /* Scales the card up to 105% when hovered */
  transform: scale(1.05);
}

.tooltip-text {
  background-color: #f9f9f9; /* Light background */
  color: #333; /* Dark text for contrast */
  font-size: 14px; /* Slightly larger text */
  line-height: 1.5; /* More space between lines */
  max-width: 300px; /* Maximum width for readability */
  padding: 8px; /* Padding inside the tooltip */
  border: 1px solid #ccc; /* Optional border */
}
