section {
  background-color: #fff;
  padding: 0.5em;
  /* margin-bottom: 2em; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

/* Hero Section */

.hero {
  background-image: linear-gradient(to bottom, #333, #555);
  background-size: 100% 300px;
  background-position: 0% 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.hero h1 {
  font-size: 36px;
}

/* Features Section */

.features ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.features li {
  margin-bottom: 10px;
}

.features li:before {
  content: "•";
  margin-right: 10px;
  color: #333;
}

/* Testimonials Section */

.testimonials blockquote {
  border-left: 4px solid #333;
  padding-left: 10px;
  margin-bottom: 20px;
}

.testimonials cite {
  font-size: 18px;
  font-style: italic;
  color: #666;
}

/* Call-to-Action Section */

.call-to-action {
  background-color: #333;
  color: #fff;
  padding: 2em;
  text-align: center;
}

.call-to-action button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}

.call-to-action button:hover {
  background-color: #555;
}
